<template>
  <div
    class="
      text-center
      d-flex
      align-items-center
      justify-content-between
      full-screen
    "
  >
    <div class="text-center mx-auto">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
        type="grow"
        variant="primary"
      />
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BSpinner,
  },
  data() {
    return {
      loggingIn: true,
    };
  },
  methods: {
    validateAuthCode() { 
      if (!this.$route.query.code) {
        return this.$router.push("/login");
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/login/google/validate`,
          {
            code: this.$route.query.code,
          }
        )
        .then((response) => {
          useJwt.setToken(response.data.token);
          useJwt.setUserName(response.data.admin.firstname);
          useJwt.setUserEmail(response.data.admin.email);
          useJwt.setAdminClientEnabled(response.data.admin.configuration.clients);
          useJwt.setAdminGMBEnabled(response.data.admin.configuration.gmb.enabled);
          useJwt.setAdminGMBDealersEnabled(response.data.admin.configuration.gmb_for_dealers);
          useJwt.setAdminAccessControlEnabled(response.data.admin.configuration.access_control);
          useJwt.setAdminTriggersControlEnabled(response.data.admin.configuration.triggers);
          useJwt.setAdminGMBGeoEnabled(response.data.admin.configuration.gmb.items.geo)
          useJwt.setAdminGMBSummaryEnabled(response.data.admin.configuration.gmb.items.summary)
          useJwt.setAdminGMBOrganicEnabled(response.data.admin.configuration.gmb.items.organic)
          useJwt.setAdminGMBPostEnabled(response.data.admin.configuration.gmb.items.posts)
          useJwt.setAdminGMBPaidEnabled(response.data.admin.configuration.gmb.items.paid)
          useJwt.setAdminGMBReviewEnabled(response.data.admin.configuration.gmb.items.reviews)
          useJwt.setAdminGMBStoreEnabled(response.data.admin.configuration.gmb.items.stores)
          // window.location.reload(true);
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          console.log(error.response);
          this.$router.push("/login?error=google-login-error");
        });
    },
  },
  mounted() {
    this.validateAuthCode();
  },
};
</script>

<style scoped>
.full-screen {
  height: 100vh;
}
</style>